import { useCallback, useEffect, useRef } from 'react';
import useAxios from 'infrastructure/axios/useAxios';
import axios, { CancelTokenSource } from 'axios';

/**
 * Available methods to request API
 * @type {{GET: string, PUT: string, DELETE: string, POST: string, PATCH: string}}
 */
export type ApiMethod = 'GET' | 'PUT' | 'DELETE' | 'POST' | 'PATCH';
const API_METHODS: Record<ApiMethod, ApiMethod> = {
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
  POST: 'POST',
  PATCH: 'PATCH',
};

interface RequestConfig {
  data?: any;
  method?: ApiMethod;
  resource?: string;
  params?: any;
  headers?: Record<string, string>;
  paramsSerializer?: (params: any) => string;
}

export type FetchState<T = void> =
  | { status: 'idle' }
  | { status: 'loading' }
  | { status: 'success'; data: T }
  | { status: 'error'; errorMessage: string };

const { CancelToken } = axios;

/**
 * Hook used for requesting API directly from component. Can be used as a Promise or async method.
 * @returns {{methods: {GET: string, PUT: string, DELETE: string, POST: string, PATCH: string}, requestApi: (function(*, {data: *, method?: *, resource?: *}): Promise<*>)}}
 */
const useApi = () => {
  const axiosInstance = useAxios();
  const controller = useRef<CancelTokenSource | null>(null);

  const assignNewCancelToken = useCallback(() => {
    const source = CancelToken.source();
    controller.current = source;
  }, []);

  useEffect(() => {
    assignNewCancelToken();
    /** Setting controller only once at first */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Cancel Request function cancels all previously started requests generated from single useApi hook. */
  const cancelRequest = useCallback(() => {
    if (controller?.current) {
      controller.current.cancel('Operation canceled due to new request.');
    }
    assignNewCancelToken();
  }, [assignNewCancelToken]);

  /**
   *
   * @param endpoint - URL where request should fly
   * @param data - payload for the request, query params or body data
   * @param method - request method from API_METHODS
   * @param resource - API resource. We have currently: app, auth, exports, system.
   * @param params - GET request query params
   * @returns {Promise<unknown>}
   */
  const requestApi = useCallback(
    (
      endpoint: string,
      {
        data,
        method = 'GET',
        resource = 'app',
        params = {},
        headers,
        paramsSerializer,
      }: RequestConfig
    ) =>
      axiosInstance.request({
        method,
        url: new URL(`api/${resource}${endpoint}`, process.env.API_URL).href,
        data,
        params,
        paramsSerializer,
        cancelToken: controller.current?.token,
        headers: {
          ...headers,
        },
      }),
    [axiosInstance]
  );
  return { requestApi, methods: API_METHODS, cancelRequest };
};

export default useApi;
